import { Uneeq } from 'uneeq-js'
import { UneeqAvatarState, UneeqConnectionState, UneeqEvent } from '@Entities/Enums'
import { Observer, Subject } from 'rxjs'

export interface IUneeqManagerProps {
    server: string,
    token: string,
    avatarId: string,
    avatarVideoContainerElement: HTMLDivElement | null,
    localVideoContainerElement: HTMLDivElement | null,
    backgroundUrl : string
}

export class UneeqManager {
    avatarState: UneeqAvatarState = UneeqAvatarState.UNAVAILABLE
    connectionState: UneeqConnectionState = UneeqConnectionState.NOT_CONNECTED
    private uneeqInstance: Uneeq | null = null;
    private readonly observers: Record<UneeqEvent, Subject<any>> = {} as Record<UneeqEvent, Subject<any>>;
    private _loadingPercentage: number = 0;
    private _LoadingPercentageObservable: Subject<number> = new Subject<number>();
    public onLoadingPercentageChange = (callback: (percentage: number) => void) => {
        this._LoadingPercentageObservable.subscribe(callback)
    }

    get LoadingPercentage(): number {
        return this._loadingPercentage
    }

    set LoadingPercentage(value: number) {
        this._loadingPercentage = value
        this._LoadingPercentageObservable.next(value)
    }

    //Singleton
    private static instance: UneeqManager;
    public static getInstance(): UneeqManager {
        if(!UneeqManager.instance)
            UneeqManager.instance = new UneeqManager();
        return UneeqManager.instance
    }

    private constructor() {
    }

    subscribeEventObserver(event:UneeqEvent, observer: Observer<any>) {
        // Check if the observer already exists, if not create it
        if(!this.observers[event])
            this.observers[event] = new Subject<any>()

        this.observers[event].subscribe(observer)
    };

    notifyEventObservers(message: any) {
        const uneeqEvent = message.uneeqMessageType as UneeqEvent;

        if(this.observers[uneeqEvent])
            this.observers[uneeqEvent].next(message)
    }

    async connect(props:IUneeqManagerProps){
        // Check if token was initialized or if we are already connecting, prevent multiple connections
        if(this.connectionState !== UneeqConnectionState.NOT_CONNECTED) {
            console.warn('Connecting to Uneeq is already in progress or has already been completed')
            return this;
        }

        if(!props.avatarVideoContainerElement || !props.localVideoContainerElement)
            throw new Error("Avatar e local video container must be defined");

        // Set state to connecting, prevent multiple connections
        this.connectionState = UneeqConnectionState.CONNECTING

        this.uneeqInstance = new Uneeq({
            url: props.server,
            conversationId: props.avatarId,
            messageHandler: (message: any) => this.notifyEventObservers(message),
            avatarVideoContainerElement: props.avatarVideoContainerElement,
            localVideoContainerElement: props.localVideoContainerElement,
            sendLocalAudio: false,
            sendLocalVideo: false,
            customData: null,
            voiceInputMode: 'PUSH_TO_TALK',
            logging:false,
            backgroundImageUrl: props.backgroundUrl

        })

        this.uneeqInstance.setLoggerEnabled(false);
        await this.uneeqInstance.initWithToken(props.token);
        this.connectionState = UneeqConnectionState.CONNECTED;

        // Return state
        return this;
    }

    public stopSpeaking() {
        // To avoid create a flood of request to uneeq.
        if(this.avatarState === UneeqAvatarState.SPEAKING)
            this.uneeqInstance?.stopSpeaking()
    }

    public getSessionId = () => {
        return this.uneeqInstance?.sessionId || null
    }

}
