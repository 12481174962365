import { Stage } from '@Entities/Enums'
import {InterfaceManager} from '@Services'
import { useEffect, useState } from 'react'

export function Background(props: {stage?: Stage}) {
        const interfaceManager= InterfaceManager.getInstance()
        const [stage, setStage] = useState(props.stage? props.stage : Stage.INITIAL)   
        interfaceManager.onStageChanged((stage) => { 
            setStage(stage) 
        })
    useEffect(() => {
        if(props.stage!==undefined) {
            setStage(props.stage)
        }
    },[props.stage])
        return (
            <div className={`background ${stage===Stage.INITIAL ? 'initial' : stage===Stage.LOADING ? 'loading' : 'default'}`}>
                <div className="videoContainerOverlay"/>
                <div className="img" />
            </div>
        )
    }
