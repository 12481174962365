import React, { Component, createRef, Key, RefObject } from 'react'
import { Message } from '@Entities'
import { Group, GroupAlignment, GroupExpands } from '@Components'

export interface ContentAreaProps {
    BottomBar?: React.ReactNode
    Mode: ContentAreaMode
    Content?: React.ReactNode[]
}

export enum ContentAreaMode {
    extra = 'extra',
    chat = 'chat',
    default = 'default',
}

export enum ScrollState {
    SCROLL_TOP = 'scrollTop',
    SCROLL_MID = 'scrollMid',
    SCROLL_BOTTOM = 'scrollBottom',
    SCROLL_NONE = '',
}

export interface GroupReference {
    idx: number
    key: Key
    content: React.ReactNode
    ref: RefObject<Group>
    message: Message
}

interface ContentAreaState {
    Groups: GroupReference[]
    GroupSelected: Key | undefined
    ScrollClassState: ScrollState
}

export class ContentArea extends Component<ContentAreaProps, ContentAreaState> {
    public scrollRef: React.RefObject<HTMLDivElement>
    constructor(props: ContentAreaProps) {
        super(props)
        this.state = {
            Groups: [],
            ScrollClassState: ScrollState.SCROLL_NONE,
            GroupSelected: undefined,
        }
        this.scrollRef = React.createRef()
        this.handleScroll = this.handleScroll.bind(this)
    }

    componentDidUpdate(prevProps: Readonly<ContentAreaProps>, prevState: Readonly<ContentAreaState>, snapshot?: any) {
        this.checkGroupsVisibility()
    }
    componentDidMount(): void {
        if (this.scrollRef.current) {
            const scrollable =
                this.scrollRef.current?.scrollHeight > this.scrollRef.current?.clientHeight
                    ? ScrollState.SCROLL_TOP
                    : ScrollState.SCROLL_NONE
            this.setState({ ScrollClassState: scrollable })
        }
    }
    private checkGroupsVisibility() {
        const groups = this.getAllGroupsRef()

        groups.forEach((group) => {
            group.ref.current && group.ref.current.setState({ Visible: true })
        })

        if (this.state.GroupSelected) {
            groups
                .filter((group) => group.key !== this.state.GroupSelected)
                .forEach((group) => {
                    group.ref.current && group.ref.current.setState({ Visible: false })
                })
        }
    }

    createContentGroup = async (message: Message): Promise<GroupReference> => {
        const groupRef = createRef<Group>()
        const newContentGroup = {
            idx: this.state.Groups.length,
            key: message.getGroupId(),
            content: <Group
                hasMargins={true}
                alignment={GroupAlignment.center}
                expands={GroupExpands.fixed}
                key={message.getGroupId()}
                ref={groupRef}
            />,
            ref: groupRef,
            message: message
        }

        return new Promise((resolve) =>
            this.setState(
                (prevState) => ({
                    Groups: [...prevState.Groups, newContentGroup],
                }),
                () => {
                    resolve(newContentGroup)
                }
            )
        )
    }

    getAllGroupsRef = (): GroupReference[] => {
        return this.state.Groups
    }

    getGroupByRecordId(recordId: string) {
        return this.state.Groups.find((r) => r.key === recordId)
    }

    getLastGroupRef = (): GroupReference | undefined => {
        if (this.state.Groups.length === 0) return undefined
        return this.state.Groups[this.state.Groups.length - 1]
    }

    removeContentGroupReferenceByKey = async (key: Key) => {
        return new Promise((resolve) =>
            this.setState(
                {
                    Groups: [...this.state.Groups.filter((group) => group.key !== key)],
                },
                () => {
                    resolve(true)
                }
            )
        )
    }

    removeContentGroupsReferenceByKey(key: Key[]) {
        return new Promise((resolve) => {
            this.setState(
                {
                    Groups: [...this.state.Groups.filter((group) => !key.includes(group.key))],
                },
                () => {
                    resolve(true)
                }
            )
        })
    }

    getScrollPosition = (): ScrollState => {
        const scrollPosition = ScrollState.SCROLL_TOP
        if (this.scrollRef && this.scrollRef.current && this.scrollRef.current.scrollTop) {
            const result =
                this.scrollRef.current?.scrollHeight -
                this.scrollRef.current?.clientHeight -
                this.scrollRef.current?.scrollTop
            return result > 1 ? ScrollState.SCROLL_MID : ScrollState.SCROLL_BOTTOM
        }
        return scrollPosition
    }
    handleScroll = () => this.setState({ ScrollClassState: this.getScrollPosition() })

    render() {
        return (
            <div
                className={'contentArea ' + this.props.Mode}
                onScroll={this.handleScroll}
                onScrollCapture={this.handleScroll}
            >
                <div
                    ref={this.scrollRef}
                    className={`contentScrollArea ${this.state.ScrollClassState}`}
                    id="contentScrollArea"
                >
                    {this.state.Groups.map((group) => group.content)}
                    {this.props.Content}
                </div>
                <div className="bottomBar">{this.props.BottomBar}</div>
            </div>
        )
    }
}
