import {InterfaceManager} from "@Services"
import { IModal } from "@Entities/Interfaces"
import {ContentArea, ContentAreaMode} from "../ContentArea/ContentArea"
import {ModalTemplates} from "@Entities/Enums/ModalTemplates"
import {Bar, ButtonState, Group, GroupAlignment, IconButton, IconVector} from "@Components"
import {Label} from "../Label/Label"
import {Translation} from "@Entities/Enums"
import {t} from "i18next"
import {IconLabelButton} from "../Button/EditorialButton/IconLabelButton/IconLabelButton"
import React, {useEffect, useState} from "react"


export function Modal(props: {modal?: IModal, onClose?: () => void}) {
    const interfaceManager=InterfaceManager.getInstance()
    const [modal,setModal]=useState<IModal>(props.modal? props.modal : interfaceManager.Modal)
    
    useEffect(() => {
        if(props.modal)
            setModal(props.modal)
    },[props])

    interfaceManager.onModalChange((modal) => {
        setModal(modal)
    })

    if(!modal.template||modal.template.length===0) return null
    return (
        <div className={`modal ${modal.open? 'visible':'hidden'}`} >
            {(!Object.values<string>(ModalTemplates).includes(modal.template))&&
                  <div className="modalContainer">
                  <Bar Right={[<IconButton IconVector={IconVector.CLOSE}
                      OnClick={() => {
                          props.onClose && props.onClose()
                          interfaceManager.setModal({
                              ...modal,
                              open: false
                          })
                      }}/>
                    ]} />
                    <ContentArea Mode={ContentAreaMode.default} Content={[<Group alignment={GroupAlignment.center} expands={false} hasMargins={true} isWrapped={false} HtmlRef={React.createRef<HTMLInputElement>()}><Label Text={"Unrecognized modal template"}/></Group>]} />
                </div>}
            {modal.template===ModalTemplates.RESTART &&
                <div className="modalContainer">
                    <Bar Right={[<IconButton IconVector={IconVector.CLOSE}
                        OnClick={() => {
                            props.onClose && props.onClose()
                            interfaceManager.setModal({
                                ...modal,
                                open: false
                            })
                        }}/>
                    ]} />
                    <ContentArea Mode={ContentAreaMode.default} Content={[<Group alignment={GroupAlignment.center} expands={false} hasMargins={true} isWrapped={false} HtmlRef={React.createRef<HTMLInputElement>()}><Label Text={t(Translation.MODAL_RESTART_PROMPT)}/></Group>]} />
                    <Bar Name="bottomModalBar" Right={[
                        <Group alignment={GroupAlignment.center} expands={false} hasMargins={false} isWrapped={true} HtmlRef={React.createRef<HTMLInputElement>()}>
                            <IconLabelButton State={ButtonState.DEFAULT} IconVector={IconVector.ARROW_LEFT} Label={t(Translation.MODAL_RESTART_DENY)}  OnClick={() => {
                                props.onClose && props.onClose()
                                interfaceManager.setModal({
                                ...modal,
                                open: false
                            })
                        }} />
                            <IconLabelButton State={ButtonState.DEFAULT} IconVector={IconVector.ARROW_RIGHT} Label={t(Translation.MODAL_RESTART_CONFIRM)} OnClick={() => {
                                window.location.reload();
                            }} />
                        </Group>
                    ]} />
                </div>}
            <div className="modalBackground" onClick={() => {
                if(modal.closeWithBackground) {
                        props.onClose && props.onClose()
                        interfaceManager.setModal({...modal, open: false})
                    }
        }} />
        </div>
    )
}