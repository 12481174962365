import { DigitalHumanState, Stage } from '@Entities/Enums'
import { IModal } from '@Entities/Interfaces'
import { Subject } from 'rxjs'

export class InterfaceManager {

    private _DigitalHumanStateObservable=new Subject<{previous: DigitalHumanState,current: DigitalHumanState}>();
    private _StageObservable: Subject<Stage>=new Subject<Stage>();
    private _ModalObservable: Subject<IModal>=new Subject<IModal>();

    private _DigitalHumanState=[DigitalHumanState.AVATAR]
    private _Stage: Stage=Stage.INITIAL
    private _Modal: IModal={open: false, template: '', closeWithBackground: false}
    handleDigitalHumanState(callback: (props: {previous: DigitalHumanState,current: DigitalHumanState}) => void) {
        this._DigitalHumanStateObservable.subscribe(callback)
    }
    verifyDigitalHumanState=() => {
        this._DigitalHumanStateObservable.next({
            previous: this.getPreviousDigitalHumanState(),
            current: this.getCurrentDigitalHumanState()
        })
    }

    onStageChanged(callback: (state: Stage) => void) {
        this._StageObservable.subscribe(callback)
    }

    onModalChange(callback: (state: IModal) => void) {
        this._ModalObservable.subscribe(callback)
    }

    set Modal(value: IModal) {
        this._Modal=value
        this._ModalObservable.next(value)
    }
    set Stage(value: Stage) {
        this._StageObservable.next(value)
        this._Stage=value
    }
    setModal=(value: IModal) => {
        this._Modal=value;
        this._ModalObservable.next(value)
    }
    setDigitalHumanState=(value: DigitalHumanState) => {
        this._DigitalHumanStateObservable.next({previous: this._DigitalHumanState[this._DigitalHumanState.length-1],current: value})
        this._DigitalHumanState.push(value)
    }

    changePreviousDigitalHumanState=(value: DigitalHumanState) => {
        if(this._DigitalHumanState.length<2) return
        this._DigitalHumanState[this._DigitalHumanState.length-2]=value
    }

    getStateStack(): DigitalHumanState[] {
        return this._DigitalHumanState
    }
    getModalState(): IModal{
        return this._Modal
    }
    getCurrentDigitalHumanState(): DigitalHumanState {
        return this._DigitalHumanState[this._DigitalHumanState.length - 1]
    }

    getPreviousDigitalHumanState(): DigitalHumanState {
        if(this._DigitalHumanState.length === 1) return DigitalHumanState.AVATAR
        return this._DigitalHumanState[this._DigitalHumanState.length - 2]
    }

    get DigitalHumanState(): DigitalHumanState[] {
        return this._DigitalHumanState;
    }

    get Stage(): Stage {
        return this._Stage
    }
    get Modal(): IModal {
        return this._Modal
    }
    private static instance: InterfaceManager;

    public static getInstance(): InterfaceManager {
        if(!InterfaceManager.instance)
            InterfaceManager.instance = new InterfaceManager();
        return InterfaceManager.instance
    }

    private constructor() { }

}