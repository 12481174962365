import { IElement } from '@Entities/Interfaces'
import React, { Component, createRef, Key, RefObject, JSXElementConstructor } from 'react'
import { IconLabelButtonProps, LabelButton, LabelButtonProps } from '../Button/Index'
import { Bubble, BubbleProps } from '../Bubble/Bubble'
import { Incrementer, IncrementerProps } from '../Input/Incrementer/Incrementer'
import { ElementType } from '@Entities/Enums'

const classNames = require('classnames')

export enum GroupAlignment {
    center = 'center',
    left = 'left',
    right = 'right',
}
export enum GroupExpands {
    fixed = 'fixed',
}

export interface GroupProps {
    alignment: GroupAlignment.center | GroupAlignment.left | GroupAlignment.right
    expands: boolean | GroupExpands.fixed
    hasMargins: boolean
    isWrapped?: boolean
    HtmlRef?: RefObject<HTMLInputElement>
    children?: React.ReactNode
    Elements?: ElementReference[]
    Visible?: boolean
    name?: string
    className?: string
}

interface GroupState {
    Elements: ElementReference[]
    Visible: boolean
}

export interface ContentElementProps {
    frameElement: IElement
    component: LabelButton | Bubble | Incrementer | JSXElementConstructor<any>
    props: LabelButtonProps | IconLabelButtonProps | BubbleProps | IncrementerProps | any
}

export interface ElementReference {
    idx: number
    id: string |null
    type: ElementType
    key: Key | null
    content: React.ReactNode
    frameElement: IElement
    getRef: () => LabelButton | Bubble | Incrementer
}

export class Group extends Component<GroupProps, GroupState> {
    constructor(props: GroupProps) {
        super(props)
        this.state = {
            Visible: this.props.Visible || true,
            Elements: this.props.Elements || [],
        }
    }

    private renderElement = async (type: ElementType, elementModel: IElement, elementReact: React.ReactNode, ref: RefObject<any>): Promise<ElementReference> => {
        const newElementReference: ElementReference =  {
            idx: this.state.Elements.length,
            id: React.isValidElement(elementReact) ? elementReact.key : "",
            key: React.isValidElement(elementReact) ? elementReact.key : null,
            type,
            frameElement:elementModel,
            content:elementReact,
            getRef: () => {
                if(!ref.current)
                    throw new Error("groupRef.current is null");
                return ref.current
            }
        }

        return new Promise((resolve) =>
            this.setState(prevState => ({
                Elements: [...prevState.Elements, newElementReference]
            }), () => {
                resolve(newElementReference);
            })
        );
    };

    getAllElementByType = (type: ElementType): ElementReference[] => {
        return this.state.Elements.filter((element) => element.type === type)
    }


    getElement = (element: IElement) : ElementReference | undefined => {
        return this.state.Elements.find((e) => e.id === element.id);
    }

    cleanHighlights = () => {
        const promises: Promise<boolean>[] = []
        this.state.Elements.forEach((element) => {
            const elementRef = element.getRef();
            if (elementRef) {
                promises.push(new Promise<boolean>(resolve => {
                    // @ts-ignore
                    elementRef.setState({ Highlighted: false }, () => {
                        resolve(true)
                    })
                }))
            }
        })
        return Promise.all(promises);
    }

    addElement(params): Promise<ElementReference | undefined> {
        if(!params) return Promise.resolve(undefined);
        const ref = createRef<typeof params.component>();
        const reactElement = React.createElement(params.component as React.ComponentClass<typeof params.component>, { ref, ...params.props });
        return this.renderElement(params.type, params.element, reactElement , ref);
    }

    render(): React.ReactNode {
        const groupClass = classNames({
            left: this.props.alignment === GroupAlignment.left,
            right: this.props.alignment === GroupAlignment.right,
            center: this.props.alignment === GroupAlignment.center,
            expands: this.props.expands === true,
            hasFixedWidthChildren: this.props.expands === GroupExpands.fixed,
            hasMargins: this.props.hasMargins,
            isWrapped: this.props.isWrapped,
        })

        return (
            <div className={`group ${this.props.className} ${groupClass}`}>
                {this.state.Elements.map((element) => element.content)}
                {this.props.children}
            </div>
        )
    }
}