import React, { useEffect, useState } from 'react'
import { Caption } from '@Components'
import { IServices } from '@Entities/Interfaces/IServices'
import { DigitalHumanState } from '@Entities/Enums'

export interface CaptionControlProps {
    Services: IServices
}

export function CaptionController(props: CaptionControlProps) {

    // CurrentFrame state
    const [message, setMessage] = useState<String>('')
    const [digitalHumanState, setDigitalHumanState] = useState(
        props.Services.InterfaceManager.getCurrentDigitalHumanState()
    )

    const registerRecordHandler = () => {
        props.Services.HistoryManager.onMessageStart(async (message) => {
            setMessage(message?.element?.label as string)
        })

        props.Services.HistoryManager.onMessageStop(_ => {
            setMessage('')
        })
    }

    useEffect(() => {
        registerRecordHandler()
        setDigitalHumanState(props.Services.InterfaceManager.getCurrentDigitalHumanState())
    })

    return (
        <Caption
            State={props.Services.SettingManager.captionStyle}
            AvatarMessage={message}
            CaptionlessLayout={digitalHumanState === DigitalHumanState.CHAT}
        />
    )
}
