import { Expose } from 'class-transformer'

export class ContentConfig {
    //This event will be called as soon as the user open the webpage
    @Expose()
    WelcomeEvent?: string

    @Expose()
    LoadingMessages: string[] = []

    @Expose()
    AvatarName: string = ''
}

export type Environment = {
    name?: string
    id?: string
}

export class BackendConfig {
    @Expose()
    Url: string = ''

    @Expose()
    DefaultLanguage: string = 'en'

    @Expose()
    PersonaId: string = ''

    @Expose()
    Env: Environment = {}
}

export class ApplicationConfig {
    @Expose()
    logging: boolean = true
}

export class BugherdConfig {
    @Expose()
    enabled: boolean = false
    key: string = ''
}

export class BackgroundConfig {
    @Expose()
    url: string = ''
}

export class I18nConfig {
    
}

export class DigitalHumanConfig {
    application: ApplicationConfig = new ApplicationConfig()
    content: ContentConfig = new ContentConfig()
    backend: BackendConfig = new BackendConfig()
    bugherd: BugherdConfig = new BugherdConfig()
    background: BackgroundConfig = new BackgroundConfig()
    i18n: I18nConfig = new I18nConfig()
}
