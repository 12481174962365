import React from 'react'
export interface HighlightProps {
    Name: string
    Highlighted: boolean
    Child: React.ReactElement
}

export class Highlight extends React.Component<HighlightProps, HighlightProps> {
    componentDidUpdate(prevProps: Readonly<HighlightProps>) {
        if (prevProps !== this.props) this.setState({ ...prevProps, ...this.props })
    }

    constructor(props: HighlightProps) {
        super(props)
        this.state = {
            Name: props.Name || '',
            Highlighted: props.Highlighted || false,
            Child: props.Child || null,
        }
    }

    render() {
        return (
            (this.state.Highlighted||this.state.Child?.props?.src||this.state.Child?.props?.className?.includes("media")) ?
                <div className={`highlight ${this.props.Highlighted? this.state.Name:'inactive'}`}>{this.state.Child}</div>
                : this.state.Child
         )
        }
}
// 