import React from 'react'

export interface BarProps {
    Name?: string
    Left?: React.ReactNode[]
    Center?: React.ReactNode[]
    Right?: React.ReactNode[]
}

/** Bar is container that provider a simplified way to positioning other elements */
export class Bar extends React.Component<BarProps, {}> {
    render() {
        /*  Default values if empty */
        const left = this.props.Left || []
        const center = this.props.Center || []
        const right = this.props.Right || []

        return (
            <div className={`bar basic ${this.props.Name ? this.props.Name : ''}`}>
                {left.length > 0 &&
                    <div className="left">
                        {left.map((component, index) => (
                            <div key={`bar-left-${index}`} className={`left-${index}`}>{component}</div>
                        ))}
                    </div>
                }
                {center.length > 0 &&
                    <div className="center">
                        {center.map((component, index) => (
                            <div key={`bar-center-${index}`} className={`center-${index}`}>{component}</div>
                        ))}
                    </div>
                }
                {right.length > 0 &&
                    <div className="right">
                        {right.map((component, index) => (
                            <div key={`bar-right-${index}`} className={`right-${index}`}>{component}</div>
                        ))}
                    </div>
                }
            </div>
        )
    }
}
