import React,{
    useRef,
    useState,
    useLayoutEffect,
} from 'react';
import {IconButton, IconVector} from "@Components"
  interface PlayerProps {
    fullscreen?: boolean
    source: string
  }
  const Player: React.FC<PlayerProps> = ({ fullscreen, source }) => {
    const videoRef = useRef<HTMLVideoElement>(null)
    const progressRef = useRef<HTMLProgressElement>(null)
    const [progress, setProgress] = useState(0)
    const [mode, setMode] = useState(fullscreen ? true : false)
    const [ready, setReady] = useState(false)
    const [played, setPlayed] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    
      
    useLayoutEffect(() => {
      const handleVideoEnd = () => {
          console.log('video ended')
          // ToDo: add next intent trigger
        }
      const vidRef = videoRef.current
        if(vidRef) {
          vidRef.addEventListener('ended', handleVideoEnd)
          vidRef.onplay=() => {
                 setPlayed(true)
          }
      }
      return () => vidRef?.removeEventListener('ended', handleVideoEnd)
    }, [videoRef])
  
    const handleProgress = () => {
      if (videoRef?.current) {
        const duration = videoRef.current?.duration
        const currentTime = videoRef.current?.currentTime
        const progress = (currentTime / duration) * 100
        setProgress(progress)
      }
    }
    const togglePlay = () => {
        isPlaying ? videoRef?.current?.pause() : videoRef?.current?.play()
        setIsPlaying(isPlaying => !isPlaying)
      }
      
      const toggleFullScreen=() => {
        setMode(mode => !mode)
      }
      
    const handleClick = e => {
      if (videoRef?.current) {
        let rect = e.currentTarget.getBoundingClientRect()
        let clickX = e.clientX
        let boundLeft = Math.floor(rect?.left)
        let boundRight = Math.floor(rect?.right)
        let ratio = (clickX - boundLeft) / (boundRight - boundLeft)
        let duration = videoRef.current.duration
        videoRef.current.currentTime = duration * ratio
      }
      }
      
    return (
        <div className={`videoPlayer ${mode? 'fullscreen':''} ${ready? 'visible':'hidden'} `}>
            {
            // TODO: add onClick listener to videocontainer?
            }
            <div className='playerContainer'>
                {(ready && !played)&&
                <>
                    <div className={`overlay`} />
                    <IconButton IconVector={IconVector.PLAY} OnClick={togglePlay}/>
                </>}
                <video src={source} ref={videoRef} onTimeUpdate={handleProgress} className={`${ready? 'visible':'hidden'}`}
                onCanPlay={() => {setReady(true)}} />
            </div>
            {(isPlaying || played) && (
              <div className='controlsContainer'>
                <div
                 className="controls"
                >
                        <IconButton OnClick={togglePlay}
                        IconVector={isPlaying? IconVector.PLAY:IconVector.PLAY // TODO: add pause icon, swap play icon?
                        } />
                </div>
                <progress
                  id="progress"
                  ref={progressRef}
                  value={`${progress}`}
                  onClick={e => handleClick(e)}
                  max="100"
                >
                  <span id="progress-bar"/>
                </progress>
                <div className='controls'>
                        <IconButton IconVector={IconVector.RESET} OnClick={toggleFullScreen}/>
                </div>
              </div>
            )}
          </div>
    )
  }
  
  export default Player
  