import React, { RefObject } from 'react'

export interface TextInputProps {
    State: TextInputState
    Value?: string | number
    Placeholder?: string
    Pattern?: Patterns
    Size?: number
    Name?: string
    Handler?: Function
}

export enum TextInputState {
    DISABLED = 'disabled',
    ACTIVE = 'active',
    HIDDEN = 'hidden',
    BLOCKED = 'blocked',
}

export enum TextInputWidth {
    FILL = 'fill',
    HUG = 'hug',
}

export enum Patterns {
    NUMBER = '^[0-9]*$',
}

export class TextInput extends React.Component<
    TextInputProps,
    {
        State: TextInputState
        placeholder: string
        Value: string
        HtmlRef: RefObject<HTMLInputElement>
    }
> {
    constructor(props: TextInputProps) {
        super(props)
        this.state = {
            State: props.State,
            placeholder: props.Placeholder ? props.Placeholder : '',
            HtmlRef: React.createRef(),
            Value: '',
        }
    }

    updateInput(value: string) {
        this.setState({ Value: value })
    }

    handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        if (!this.props.Pattern || e.target.value.match(this.props.Pattern)) {
            if (!this.props.Handler || (this.props.Handler && this.props.Handler(Number(e.target.value)))) {
                this.updateInput(e.target.value)
            }
        }
    }

    render() {
        return (
            <input
                type="text"
                className={`textInput ${this.state.State} basic ${this.props.Name ? this.props.Name : ''}`}
                size={this.props.Size ? this.props.Size : -1}
                ref={this.state.HtmlRef}
                placeholder={this.state.placeholder}
                value={this.state.Value}
                onChange={e => this.handleInput(e)}
            />
        )
    }
}
