import { Label, ButtonState } from '@Components'
import { Translation } from '@Entities/Enums'
import React from 'react'

export interface RadioButtonProps {
    State: ButtonState
    Label: Translation | string
    Checked: boolean
    OnClick?: () => void
    Name?: string
}

export class RadioButton extends React.Component<RadioButtonProps, {}> {
    state = {
        checked: this.props.Checked,
    }
    componentDidUpdate(prevProps: Readonly<RadioButtonProps>): void {
        if(prevProps.Checked!==this.props.Checked) {
            this.setState({
                ...this.state,
                checked: this.props.Checked,
            })
        }
    }

    render() {
        return (
            <div
                className={`radioButton ${this.props.State} basic ${
                    this.props.Name ? this.props.Name : ''
                }`}
                onClick={() => {
                    this.props.OnClick && this.props.OnClick()
                    this.setState({
                        ...this.state,
                        checked: !this.state.checked,
                    })
                }}
            >
                <div className="container">
                    <Label Text={this.props.Label} />
                    <div className="content">
                        <input
                            type="radio"
                            onChange={()=>{}}
                            checked={this.state.checked || false}
                            
                        />
                        <div className='radiobox'>
                            <div className="radiomark"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
