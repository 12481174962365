import React from 'react'
import { t } from 'i18next'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { Translation } from '@Entities/Enums'
import { Highlight } from '@Components'
import rehypeRaw from 'rehype-raw'
import { I18n } from '@Translation'

export enum LabelStyleClass {
    default = 'default',
    action = 'action',
    caption = 'caption',
    captionAccessible = 'captionAccessible',
    conversation = 'conversation',
    conversationLink = 'conversationLink',
    contentTitle = 'contentTitle',
    inputValue = 'inputValue',
    note = 'note',
    loading = 'loading',
    versionNumber = 'versionNumber',
    loadingQuotes = 'loadingQuotes',
}
export interface LabelProps {
    Name?: string
    Text?: String
    Highlighted?: boolean
    LabelStyleClass?: string
}
export class Label extends React.Component<
    LabelProps,
    {
        Highlighted: boolean
        Ref: React.RefObject<HTMLDivElement>
        Language: string
        LabelStyleClass?: string
    }
> {
    constructor(props: LabelProps) {
        super(props)
        this.state = {
            Highlighted: this.props.Highlighted ? this.props.Highlighted : false,
            Ref: React.createRef(),
            Language: I18n.language,
            LabelStyleClass: LabelStyleClass.default,
        }

        I18n.on('languageChanged', (lang) => this.setState({ Language: lang }))
    }

    static defaultProps = {
        LabelStyleClass: LabelStyleClass.default,
    }

    render() {
        if (!this.props.Text) return

        return (
            <Highlight
                Highlighted={this.state.Highlighted}
                Name="font"
                Child={
                    <ReactMarkdown
                        rehypePlugins={[rehypeRaw as any]}
                        className={`label ${this.props.Name || 'basic'} ${
                            this.props.LabelStyleClass || LabelStyleClass.default
                        }`}
                    >
                        {/* @ts-ignore */}
                        {t(this.props.Text as Translation)}
                    </ReactMarkdown>
                }
            />
        )
    }
}
