//Define Keys available in the translation file
export enum Translation {
    // Keys used in the system
    CANCEL='common:cancel',
    SEND='common:send',
    PROGRESS_BAR='common:progressBar',

    // Key used just in the StoryBook
    STORYBOOK_USER_BUBBLE='storybook:userBubble',
    STORYBOOK_BOT_BUBBLE='storybook:botBubble',
    STORYBOOK_BUTTON_LABEL='storybook:ButtonLabel',

    // Key used in Home Screen
    HOMESCREEN_BUBBLES='homeScreen:bubbles',
    HOMESCREEN_AGREEMENT='homeScreen:agreement',
    HOMESCREEN_START_BUTTON='homeScreen:startButton',

    DROPDOWN_CONTROLLER_SETTINGS='dropdownContoller:settings',
    DROPDOWN_CONTROLLER_DIGITAL_HUMAN_VALUES='dropdownContoller:digitalHuman:values',
    DROPDOWN_CONTROLLER_DIGITAL_HUMAN_FEATURES='dropdownContoller:digitalHuman:features',
    DROPDOWN_CONTROLLER_TUTORIAL='dropdownContoller:tutorial',
    DROPDOWN_CONTROLLER_PRIVACY_POLICY='dropdownContoller:privacy:privacyPolicy',
    DROPDOWN_CONTROLLER_PRIVACY_POLICY_SUBTEXT='dropdownContoller:privacy:privacyPolicySubtext',
    DROPDOWN_CONTROLLER_PRIVACY_POLICY_WITHDRAW='dropdownContoller:privacy:withdraw',
    DROPDOWN_CONTROLLER_INTERFACE_SIZE='dropdownContoller:interfaceSize',
    DROPDOWN_CONTROLLER_CAPTION_STYLE='dropdownContoller:captions:captionStyle',
    DROPDOWN_CONTROLLER_PRIVACY_POLICY_LINK='dropdownContoller:privacy:privacyPolicyLink',

    CAPTIONS_DISABLED='dropdownContoller:captions:disabled',
    CAPTIONS_TRANSPARENT='dropdownContoller:captions:transparent',
    CAPTIONS_SOLID='dropdownContoller:captions:solid',
    CAPTIONS_ACCESSIBILITY='dropdownContoller:captions:solid',
    CAPTIONS_OFF='dropdownContoller:captions:off',

    LOADING_PAGE_LOADING_BAR='loadingPage:loadingBar',
    LOADING_PAGE_LOADING_TEXT='loadingPage:loadingText',

    MODAL_RESTART_PROMPT='modal:restart:prompt',
    MODAL_RESTART_DENY='modal:restart:deny',
    MODAL_RESTART_CONFIRM='modal:restart:confirm',
}
