import React, { MouseEventHandler } from 'react'
import { ButtonState, Group, GroupAlignment, IconVector } from '@Components'
import { IconLabelButton } from '../../Button/EditorialButton/IconLabelButton/IconLabelButton'
import { LabelButton } from '../../Button/EditorialButton/LabelButton/LabelButton'
import { I18n } from '@Translation'
import { languages } from '@Entities/Enums'

export interface ButtonProps {
    Icon?: IconVector
    Label: string
    IsActive?: boolean
    OnClick?: MouseEventHandler
}

export interface LanguageGroupProps {
    Name?: string
    className?: string
    Languages: ButtonProps[]
}

export interface LanguageGroupState {
    Languages: LanguageGroupProps['Languages']
    currentLanguage: string
}

export class LanguageGroup extends React.Component<LanguageGroupProps, LanguageGroupState, ButtonProps> {
 constructor(props) {
        super(props)
        this.state = {
            Languages: props.Languages?.map(language => {
                language.IsActive = languages[language.Label] === I18n.language
                return language
            }),
            currentLanguage: I18n.language
        }
     
        I18n.on('languageChanged', (newLanguage) => {
            this.setState({
                Languages: this.state.Languages.map(language => {
                    language.IsActive = languages[language.Label] === newLanguage
                    return language
                }),
                currentLanguage: newLanguage

            }) 
        })
    }
    render(): React.ReactNode {
        return (
            this.state.Languages && (
                <Group
                    key={this.state.currentLanguage}
                    alignment={GroupAlignment.right}
                    expands={true}
                    hasMargins={false}
                    isWrapped={false}
                    className="languageGroup"
                    HtmlRef={React.createRef<HTMLInputElement>()}
                >
                    {this.state.Languages.map((item: ButtonProps) =>
                        item.Icon ? (
                            <IconLabelButton
                                State={item.IsActive ? ButtonState.ACTIVE : ButtonState.DEFAULT}
                                IconVector={item.Icon}
                                Label={item.Label}
                                key={item.Label}
                                OnClick={item.OnClick}
                            />
                        ) : (
                            <LabelButton
                                State={item.IsActive ? ButtonState.ACTIVE : ButtonState.DEFAULT}
                                key={item.Label}
                                Label={item.Label}
                                OnClick={item.OnClick}
                            />
                        )
                    )}
                </Group>
            )
        )
    }
}