import { useState, useRef } from 'react'
import { IServices } from '@Entities/Interfaces/IServices'
import {
    ButtonState,
    Caption,
    CaptionState,
    Carousel,
    Dropdown,
    DropdownAlignment,
    DropdownState,
    IconButton,
    IconLabelButton,
    IconVector,
    InterfaceSize,
    Label,
    LabelButton,
    LanguageGroup,
    Bar,
} from '@Components'
import { I18n } from '@Translation'
import { Translation } from '@Entities/Enums'

export enum DropdownTemplate {
    HOME = 'home',
    SETTINGS = 'settings',
    INTERFACE_SIZE = 'interfaceSize',
    INTERFACE_SIZE_NO_BAR = 'interfaceSizeNoBar',
    CAPTION_STYLE = 'captionStyle',
    PRIVACY_POLICY = 'privacyPlicy',
    LANGUAGE_SELECTION = 'languageGroup',
}
export interface DrodownControllerProps {
    Template: DropdownTemplate
    Icon: IconVector
    Services: IServices
    Alignment?: DropdownAlignment
    IconName?: string
    Name?: string
}

export function DropdownController(props: DrodownControllerProps) {
    const [dropdownContentActive, setDropdownContentActive] = useState(props.Template)
    const Services = props.Services
    const dropdownRef: any = useRef()
    const sessionId = props.Services.UneeqManager.getSessionId() as string;

    const handleLanguageChange = (Language: string) => {
        I18n.changeLanguage(Language)
        dropdownRef.current.changeState(DropdownState.CLOSE)
    }
    
    return (
        <Dropdown
            Icon={props.Icon}
            IconName={props.IconName}
            Alignment={props.Alignment}
            Name={props.Name}
            OnClickBeforeOpen={_ => setDropdownContentActive(props.Template)}
            ref={dropdownRef}
        >
            {dropdownContentActive === DropdownTemplate.HOME && (
                <>
                    <LanguageGroup
                        key="HomeGroupText"
                        Languages={[
                            {
                                Icon: IconVector.EMPATHY,
                                Label: Translation.DROPDOWN_CONTROLLER_DIGITAL_HUMAN_VALUES,
                                OnClick: _ => {
                                    props.Services.BackendManager.sendText('Values', sessionId)
                                    dropdownRef.current.changeState(DropdownState.CLOSE)
                                },
                            },
                            {
                                Icon: IconVector.FEATURES,
                                Label: Translation.DROPDOWN_CONTROLLER_DIGITAL_HUMAN_FEATURES,
                                OnClick: _ => {
                                    props.Services.BackendManager.sendText('Features', sessionId)
                                    dropdownRef.current.changeState(DropdownState.CLOSE)
                                },
                            },
                            {
                                Icon: IconVector.LAPTOP,
                                Label: Translation.DROPDOWN_CONTROLLER_TUTORIAL,
                                OnClick: _ => {
                                    props.Services.BackendManager.sendText('Tutorial', sessionId)
                                    dropdownRef.current.changeState(DropdownState.CLOSE)
                                },
                            },
                            {
                                Icon: IconVector.GEAR,
                                Label: Translation.DROPDOWN_CONTROLLER_SETTINGS,
                                OnClick: _ => setDropdownContentActive(DropdownTemplate.SETTINGS),
                            },
                        ]}
                    />
                </>
            )}
            {dropdownContentActive === DropdownTemplate.SETTINGS && (
                <>
                    <Bar
                        Name="dropdown-topbar"
                        Left={[
                            <IconButton
                                IconVector={IconVector.ARROW_LEFT}
                                OnClick={_ => setDropdownContentActive(DropdownTemplate.HOME)}
                                Name="DropdownTopbarIconButton"
                            />,
                            <Label Text={Translation.DROPDOWN_CONTROLLER_SETTINGS} Name="topbar-label" />,
                        ]}
                    />
                    <LanguageGroup
                        key="LanguageGroupText"
                        Languages={[
                            {
                                Icon: IconVector.TEXT_SIZE,
                                Label: Translation.DROPDOWN_CONTROLLER_INTERFACE_SIZE,
                                OnClick: _ => setDropdownContentActive(DropdownTemplate.INTERFACE_SIZE),
                            },
                            {
                                Icon: IconVector.A_UNDERSCORE,
                                Label: Translation.DROPDOWN_CONTROLLER_CAPTION_STYLE,
                                OnClick: _ => setDropdownContentActive(DropdownTemplate.CAPTION_STYLE),
                            },
                            {
                                Icon: IconVector.LOCK,
                                Label: Translation.DROPDOWN_CONTROLLER_PRIVACY_POLICY,
                                OnClick: _ => setDropdownContentActive(DropdownTemplate.PRIVACY_POLICY),
                            },
                            // {
                            //     Label: `Version n° ${version}`,
                            // }
                        ]}
                    />
                </>
            )}
            {dropdownContentActive === DropdownTemplate.LANGUAGE_SELECTION && (
                <>
                    <LanguageGroup
                        key="LanguageGroupFlags"
                        Languages={[
                            {
                                Label: 'English',
                                IsActive: I18n.language === 'en',
                                OnClick: _ => handleLanguageChange('en'),
                            },
                            {
                                Label: 'Deutsch',
                                IsActive: I18n.language === 'de',
                                OnClick: _ => handleLanguageChange('de'),
                            },
                            {
                                Label: 'Français',
                                IsActive: I18n.language === 'fr',
                                OnClick: _ => handleLanguageChange('fr'),
                            },
                            {
                                Label: 'Italiano',
                                IsActive: I18n.language === 'it',
                                OnClick: _ => handleLanguageChange('it'),
                            },
                        ]}
                    />
                </>
            )}
            {dropdownContentActive === DropdownTemplate.INTERFACE_SIZE && (
                <>
                    <Bar
                        Name="dropdown-topbar"
                        Left={[
                            <IconButton
                                IconVector={IconVector.ARROW_LEFT}
                                OnClick={_ => setDropdownContentActive(DropdownTemplate.SETTINGS)}
                                Name="DropdownTopbarIconButton"
                            />,
                            <Label Text={Translation.DROPDOWN_CONTROLLER_INTERFACE_SIZE} Name="topbar-label" />,
                        ]}
                    />
                    <InterfaceSize SettingManager={Services.SettingManager} />
                </>
            )}
            {dropdownContentActive === DropdownTemplate.INTERFACE_SIZE_NO_BAR && (
                <>
                    <InterfaceSize SettingManager={Services.SettingManager} />
                </>
            )}
            {dropdownContentActive === DropdownTemplate.CAPTION_STYLE && (
                <>
                    <Bar
                        Name="dropdown-topbar"
                        Left={[
                            <IconButton
                                IconVector={IconVector.ARROW_LEFT}
                                OnClick={_ => setDropdownContentActive(DropdownTemplate.SETTINGS)}
                                Name="DropdownTopbarIconButton"
                            />,
                            <Label Text={Translation.DROPDOWN_CONTROLLER_CAPTION_STYLE} Name="topbar-label" />,
                        ]}
                    />
                    <Carousel
                        onSlideChange={(slideActive) => (Services.SettingManager.captionStyle = slideActive.value)}
                        slides={[
                            {
                                content: (
                                    <Caption
                                        AvatarMessage={CaptionState.DISABLED}
                                        State={CaptionState.OFF}
                                        Name="toppy"
                                    />
                                ),
                                value: CaptionState.DISABLED,
                                isActive: Services.SettingManager.captionStyle === CaptionState.DISABLED,
                            },
                            {
                                content: (
                                    <Caption
                                        AvatarMessage={CaptionState.TRANSPARENT}
                                        State={CaptionState.TRANSPARENT}
                                        Name="toppy"
                                    />
                                ),
                                value: CaptionState.TRANSPARENT,
                                isActive: Services.SettingManager.captionStyle === CaptionState.TRANSPARENT,
                            },
                            {
                                content: (
                                    <Caption
                                        AvatarMessage={CaptionState.SOLID}
                                        State={CaptionState.SOLID}
                                        Name="toppy"
                                    />
                                ),
                                value: CaptionState.SOLID,
                                isActive: Services.SettingManager.captionStyle === CaptionState.SOLID,
                            },
                            {
                                content: (
                                    <Caption
                                        AvatarMessage={CaptionState.ACCESSIBILITY}
                                        State={CaptionState.ACCESSIBILITY}
                                        Name="toppy"
                                    />
                                ),
                                value: CaptionState.ACCESSIBILITY,
                                isActive: Services.SettingManager.captionStyle === CaptionState.ACCESSIBILITY,
                            },
                        ]}
                    />
                </>
            )}
            {dropdownContentActive === DropdownTemplate.PRIVACY_POLICY && (
                <>
                    <Bar
                        Name="dropdown-topbar"
                        Left={[
                            <IconButton
                                IconVector={IconVector.ARROW_LEFT}
                                OnClick={_ => setDropdownContentActive(DropdownTemplate.SETTINGS)}
                                Name="DropdownTopbarIconButton"
                            />,
                            <Label Text={Translation.DROPDOWN_CONTROLLER_PRIVACY_POLICY} Name="topbar-label" />,
                        ]}
                    />
                    <div className={`dropdown-content`}>
                        <IconLabelButton
                            IconVector={IconVector.NEW_TAB}
                            Label={Translation.DROPDOWN_CONTROLLER_PRIVACY_POLICY_LINK}
                            State={ButtonState.DEFAULT}
                            Name="privacyPolicyLink"
                        />
                        <LabelButton
                            Label={Translation.DROPDOWN_CONTROLLER_PRIVACY_POLICY_WITHDRAW}
                            State={ButtonState.DEFAULT}
                            Name="editorial"
                            OnClick={_ => window.location.reload()}
                        />
                        <Label
                            Text={Translation.DROPDOWN_CONTROLLER_PRIVACY_POLICY_SUBTEXT}
                            Name="privacyPolicySubtext"
                        />
                    </div>
                </>
            )}
        </Dropdown>
    )
}
