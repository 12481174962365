import { Highlight, LinkContainer, LinkProps, ButtonState, Label } from '@Components'
import { Translation } from '@Entities/Enums'
import React, { MouseEventHandler } from 'react'

export interface LabelButtonProps {
    State: ButtonState
    Label: Translation | String
    Name?: string
    LinkProps?: LinkProps
    OnClick?: MouseEventHandler<HTMLButtonElement>
}

export interface LabelButtonStates {
    PreviousState: ButtonState
    State: ButtonState
    Highlighted: boolean
    LinkProps?: LinkProps
    Ref: React.RefObject<HTMLButtonElement>
}

export class LabelButton extends React.Component<
    LabelButtonProps,
    LabelButtonStates> {
    buttonState: ButtonState

    constructor(props: LabelButtonProps) {
        super(props)
        this.state = {
            PreviousState: this.props.State,
            State: this.props.State,
            Highlighted: false,
            LinkProps: this.props.LinkProps,
            Ref: React.createRef(),
        }

        this.buttonState = this.props.State
    }
    
    componentDidMount() {
        this.buttonState = this.props.State
    }

    componentDidUpdate() {
        this.buttonState = this.props.State
    }

    onHover: MouseEventHandler<HTMLButtonElement> = () => {
        if (this.buttonState !== ButtonState.BLOCKED) {
            this.buttonState = ButtonState.HOVER

            this.setState({
                State: ButtonState.HOVER,
            })

            if (this.state.State !== ButtonState.BLOCKED) {
                this.setState({
                    PreviousState: this.state.State,
                })
            }
        }
    }
    onLeave: MouseEventHandler<HTMLButtonElement> = () => {
        if (this.buttonState !== ButtonState.BLOCKED) {
            if (this.state.PreviousState !== ButtonState.BLOCKED) {
                this.buttonState = this.state.PreviousState

                this.setState({
                    State: this.state.PreviousState,
                })
            }

            this.setState({
                PreviousState: ButtonState.HOVER,
            })
        }
    }

    render() {
        return (
            <Highlight Name="box" Highlighted={this.state.Highlighted}
                Child= {
                    <LinkContainer Name={"LabelButtonLink"} Href={this.state.LinkProps?.Href} Target={this.state.LinkProps?.Target}
                           Child={
                                <button
                                    onMouseEnter={this.onHover}
                                    onMouseLeave={this.onLeave}
                                    disabled={this.buttonState === ButtonState.BLOCKED}
                                    ref={this.state.Ref}
                                    className={`labelButton ${this.state.State} basic ${this.props.Name ? this.props.Name : ''}`}
                                    onClick={this.props.OnClick}
                                >
                                    <div className="container">
                                        <Label Text={this.props.Label} />
                                    </div>
                                </button>
                            }
                        />
                    }
                />
            )
    }
}
