export enum ButtonState {
    PLACEHOLDER = 'placeholder',
    DEFAULT = 'default',
    BLOCKED = 'blocked',
    ACTIVE = 'active',
    ACTIVATED = 'activated',
    VISITED = 'visited',
    HOVER = 'hover',
    HIDE = 'hide'
}
