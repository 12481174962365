import { IElement } from '@Entities/Interfaces'
import { useEffect, useRef, useState } from 'react'
import {
    ButtonState,
    ContentArea,
    ContentAreaMode,
    GroupReference,
    Incrementer,
    LabelButton,
    ScrollState,
    TextInput,
    TextInputState,
} from '@Components'
import { IServices } from '@Entities/Interfaces/IServices'
import { ButtonElement, IncrementerElement, TextElement } from '@Entities'
import { Actor, DigitalHumanState, ElementType, PlayableState } from '@Entities/Enums'
import { filter, fromEvent } from 'rxjs'
import { ContentElementFactory } from '@Entities/Models/Elements/ContentElementFactory'

export const ContentAreaController = ( props: { Services: IServices }) => {

    const [digitalHumanState, setDigitalHumanState] = useState(props.Services.InterfaceManager.getCurrentDigitalHumanState());
    const contentAreaRef = useRef<ContentArea>(null)
    let textInputRef = useRef<TextInput>(null);

    const getContentAreaRef = () => {
        if (!contentAreaRef.current)
            throw new Error("contentAreaRef.current is null");
        return contentAreaRef.current
    }

    // Filter record when DigitalHumanState change between chat and extra content
    const filterRecords = (shouldFilter: boolean = false) => {
        getContentAreaRef().setState(
            {
                GroupSelected: shouldFilter
                    ? props.Services.HistoryManager.getLastMessage()?.getGroupId()
                    : undefined
            });
    }

    const showInput = (shouldShow: boolean = false) => {
        if (!textInputRef.current) return;

        textInputRef.current.setState((prevState) => {
            return {
                ...prevState,
                State: shouldShow ? TextInputState.ACTIVE : TextInputState.HIDDEN
            };
        });
    }

    const buttonClicked = async (element: IElement, contentGroupRef: GroupReference | undefined) => {
        if(!contentGroupRef)
            return new Error("contentGroupRef is null");

        if(!element.id)
            return new Error("elementKey is null");

        if (!contentGroupRef.ref || !contentGroupRef.ref.current) return;

        const sessionId = props.Services.UneeqManager.getSessionId() as string;
        const currentGroup = contentGroupRef.ref.current;
        const currentReactElement = contentGroupRef.ref.current.getElement(element);
        const currentModelElement = element as ButtonElement | IncrementerElement;
        const allGroupsReference = getContentAreaRef().getAllGroupsRef();
        const lastContentGroupReference = getContentAreaRef().getLastGroupRef()

        if(!currentReactElement?.getRef())
            return;

        //Get all buttons from the clicked group. We need to set all buttons as visited, since we have a new active button
        currentGroup.getAllElementByType(ElementType.BUTTON).forEach(elementReference => {
            const button = (elementReference.getRef() as LabelButton);
            if (button.state.State === ButtonState.ACTIVE || button.state.State === ButtonState.ACTIVATED)
                button.setState({
                    PreviousState: ButtonState.VISITED,
                    State: ButtonState.VISITED
                })
        })

        // Set as activated all buttons before the trigger that was active to activated, since we will have a new active button
        allGroupsReference
            .filter(group => group.key !== contentGroupRef.key)
            .forEach(ContentGroupReference => {
                if (!ContentGroupReference.ref || !ContentGroupReference.ref.current) return;

                ContentGroupReference.ref.current.getAllElementByType(ElementType.BUTTON).forEach(elementReference => {
                    const button = (elementReference.getRef() as LabelButton);
                    // If the button is active we need to let this as activated
                    if (button.state.State === ButtonState.ACTIVE)
                        button.setState({
                            PreviousState: ButtonState.ACTIVATED,
                            State: ButtonState.ACTIVATED
                        })
                })
            })

        //If clicked came from a group before the last groups it means
        // that we need delete the flow after the clicked
        if (contentGroupRef.idx < (lastContentGroupReference?.idx || 0)) {
            const groupsToRemove = allGroupsReference
                .filter(group => group.idx > contentGroupRef.idx)
                .flatMap(ContentGroupReference => ContentGroupReference.key)

            await getContentAreaRef().removeContentGroupsReferenceByKey(groupsToRemove);
            props.Services.HistoryManager.removeMessagesByGroupId(groupsToRemove);
        }

        const buttonReact = (currentReactElement.getRef() as LabelButton);
        const buttonModel = (currentModelElement as ButtonElement);

        buttonReact.setState({
            PreviousState: ButtonState.ACTIVE,
            State: ButtonState.ACTIVE
        })

        if(buttonModel.event && buttonModel.event !== 'capture_params'){
            await props.Services.BackendManager.sendEvent(buttonModel.event, sessionId, contentGroupRef.message.page)
        } else if (buttonModel.event === 'capture_params') {

            // When a button don't have an event it will submit the form elements
            const values = contentGroupRef.ref.current
                .getAllElementByType(ElementType.INCREMENTER)
                .map(e =>{
                    return {
                        key: e.frameElement.name, value: (e.getRef() as Incrementer).state.Value.toString()
                    }
                })
            await props.Services.BackendManager.sendParams(values, sessionId, contentGroupRef.message.page)
        }
    }

    const registerInputHandler = () => {
        if(!textInputRef.current || !textInputRef.current.state.HtmlRef.current) return;

        const subscription = fromEvent<KeyboardEvent>(
            textInputRef.current.state.HtmlRef.current,
            'keyup'
        ).pipe(filter((event) => event.key === 'Enter'))
            .subscribe((event) => {
                const input = event.target as HTMLInputElement;
                props.Services.BackendManager.sendText(input.value, props.Services.UneeqManager.getSessionId() as string)
                input.value = '';
            });
        return () => {
            // Clean up the subscription when the component is unmounted
            subscription.unsubscribe();
        };
    }

    const shouldGoBottom = (shouldGoBottom: boolean = false) => {
        if (shouldGoBottom) {
            getContentAreaRef().scrollRef.current?.lastElementChild?.lastElementChild?.scrollIntoView()
            getContentAreaRef().setState({ ScrollClassState: ScrollState.SCROLL_BOTTOM })
        }
    }

    useEffect(() => {
        filterRecords(digitalHumanState !== DigitalHumanState.CHAT)
        showInput(digitalHumanState === DigitalHumanState.CHAT)
        shouldGoBottom(digitalHumanState === DigitalHumanState.CHAT)
    }, [digitalHumanState])

    const registerHandlers = () => {
        registerInputHandler();

        props.Services.InterfaceManager.handleDigitalHumanState(async (state) => {
            setDigitalHumanState(state.current)
        });

        props.Services.HistoryManager.onMessageStart(async (message) => {

            if (message.element?.state !== PlayableState.PENDING)
                return console.info("[ContentAreaController] [registerHandlers] [element.state !== PlayableState.PENDING]", message.element?.state);

            //Verify if group it is already in the contentArea
            let contentGroup = getContentAreaRef().getGroupByRecordId(message.getGroupId());

            if (!contentGroup)
                contentGroup = await getContentAreaRef().createContentGroup(message)

            if (!contentGroup.ref || !contentGroup.ref.current) return;

            //Verify if element it is already in the group
            const renderedElement = contentGroup.ref.current.getElement(message.element);

            if (renderedElement)
                return console.info("[ContentAreaController] [registerHandlers] [renderedElement]", renderedElement);

            switch ((message.element as IElement).elementType.toString()) {
                case ElementType.INCREMENTER:
                    await contentGroup.ref.current.addElement(ContentElementFactory.createIncrementer(message.element as IncrementerElement));
                    break;
                case ElementType.TEXT:
                    const textElement = message.element as TextElement;
                    switch (textElement.chat?.toString()?.toLowerCase()) {
                        case "bubble":
                            await contentGroup.ref.current.addElement(ContentElementFactory.createBubble(message.element as TextElement, Actor.AVATAR));
                            break;
                    }
                    switch (textElement.extraContent?.toString()?.toLowerCase()) {
                        case "title":
                            await contentGroup.ref.current.addElement(ContentElementFactory.createTitle(message.element as TextElement, "ExtraContent"));
                            break;
                        case "subtitle":
                            await contentGroup.ref.current.addElement(ContentElementFactory.createSubTitle(message.element as TextElement, "ExtraContent"));
                            break;
                    }
                    break;
                case ElementType.BUTTON:
                    await contentGroup.ref.current.addElement(
                        ContentElementFactory.createButton(
                            message.element as ButtonElement,
                            () => buttonClicked(message.element as ButtonElement, contentGroup)
                        )
                    );
                    break;
            }

            getContentAreaRef().scrollRef.current?.lastElementChild?.lastElementChild?.scrollIntoView({ behavior: 'smooth' })
        });
    }

    useEffect(() =>  {
        registerHandlers()
    }, [])

    return <ContentArea Mode={ContentAreaMode.default} ref={contentAreaRef} key={"contentArea"} BottomBar={ <TextInput Name={"chatInput"} State={TextInputState.ACTIVE}  ref={textInputRef} />} />
}
