import { UneeqEventObserver } from './UneeqEventObserver'
import { IServices } from '@Entities/Interfaces/IServices'

export class AvatarSessionEndedObserver extends UneeqEventObserver {

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(services: IServices) { super(services) }

    next(value: any): void {
        window.location.reload()
    }

    complete = (): void => console.log('Avatar Session Ended Observer - executed');
    error = (err: any): void => console.error(`Avatar Session Ended Observer`, err);
}
