import { useEffect, useRef, useState } from 'react'
import { DigitalHumanState, ElementType, Stage } from '@Entities/Enums'
import { ButtonState, DropdownAlignment, IconButton, IconVector, Logo, Bar, DigitalHumanContainer, MainContainer, VideoContainer, LabelButton } from '@Components'
import { CaptionController } from './ContentControllers'
import { ContentAreaController } from './ContentControllers/ContentAreaController'
import { IServices } from '@Entities/Interfaces/IServices'
import { Message, UneeqSession } from '@Entities'
import { DropdownController, DropdownTemplate } from './ContentControllers/DropdownController'

export function DigitalHumanController(services: IServices) {

    // Create Refs
    const documentRef = useRef<Document>(document)
    const htmlRef = useRef<HTMLElement>(document.body.parentElement)
    const localVideoRef = useRef<HTMLDivElement|null>(null)
    const avatarVideoRef = useRef<HTMLDivElement|null>(null)
    const pushToTalkButtonRef = useRef<IconButton|null>(null)
    const chatPanelControlRef = useRef<HTMLButtonElement|null>(null)
    // const historyForwardControlRef = useRef<IconButton|null>(null)
    // const historyPreviousControlRef = useRef<IconButton|null>(null)
    const bottomActionBarRef = useRef<Bar|null>(null)
    const [digitalHumanState, setDigitalHumanState] = useState<DigitalHumanState>(DigitalHumanState.AVATAR)
    const [containerVisibility, setContainerVisibility] = useState(false)
    const sessionId = services.UneeqManager.getSessionId() as string;

    const toggleChatDigitalHumanState = (state: DigitalHumanState) => {
        if(state === DigitalHumanState.SIDE_PANEL)
            return;

        let newState;
        if(services.InterfaceManager.getCurrentDigitalHumanState() === DigitalHumanState.CHAT){
            //Todo: Check if the current record has extra content
            newState = services.HistoryManager.hasExtraContentByLastGroupId() ? DigitalHumanState.EXTRA_CONTENT : DigitalHumanState.AVATAR
        }else{
            newState = DigitalHumanState.CHAT
        }
        setDigitalHumanState(newState);
        services.InterfaceManager.setDigitalHumanState(newState);
    }

    const chatPanelToggle = () => toggleChatDigitalHumanState(DigitalHumanState.CHAT);

    // const historyPreviousControl = async () => {
    // }

    // const historyForwardControl = async () => {
    // }

    

    // Effects
    // When mount the component we need to initialize the digital human and the services
    useEffect(() => {
        (async() => {
            // services.InterfaceManager.handleDigitalHumanState( (states) => {})

            services.InterfaceManager.onStageChanged(async (state) => {
                if (state === Stage.LOADING) {
                    // Get the settings from the backend
                    const uneeqSession = (await services.BackendManager.getUneeqSession()) as UneeqSession

                    // Initialize the Uneeq with the settings sent by the backend
                    await services.UneeqManager.connect({
                        token: uneeqSession.token,
                        server: uneeqSession.server,
                        avatarId: uneeqSession.avatarId,
                        localVideoContainerElement: localVideoRef.current,
                        avatarVideoContainerElement: avatarVideoRef.current,
                        backgroundUrl: services.ConfigManager.config.background.url
                    })
                }

                if (state === Stage.DIGITAL_HUMAN) {
                    // Monitor the audio record service data changes
                    services.AudioRecordManager.onDataGenerated(async (data) => {
                        services.BackendManager.sendAudio(
                            data.audio.dataURL.split(',')[1],
                            services.UneeqManager.getSessionId() as string
                        ).catch((error: any) => console.error(error))
                    })

                    // Monitor any response from the backend
                    services.HistoryManager.onMessageStart((message: Message) => {
                        // To avoid space bar to trigger the previous button
                        // instead push to talk we remove the focus from the active element
                        ;(documentRef.current.activeElement as HTMLElement).blur()

                        //Just to create the right stack we always start in the Avatar and Back to the current.
                        if (services.InterfaceManager.getCurrentDigitalHumanState() !== DigitalHumanState.CHAT) {
                            services.InterfaceManager.setDigitalHumanState(DigitalHumanState.AVATAR)
                            setDigitalHumanState(DigitalHumanState.AVATAR)
                        }

                        if (!message.element) return

                        //To Avoid jump to extra content when chat is open
                        if (
                            services.InterfaceManager.getCurrentDigitalHumanState() !== DigitalHumanState.CHAT &&
                            (message.element.elementType === ElementType.INCREMENTER ||
                                message.element.elementType === ElementType.BUTTON)
                        ) {
                            services.InterfaceManager.setDigitalHumanState(DigitalHumanState.EXTRA_CONTENT)
                            setDigitalHumanState(DigitalHumanState.EXTRA_CONTENT)
                        }
                    })

                    services.AudioRecordManager.registerKeyboardEvents(documentRef)

                    if (pushToTalkButtonRef.current) {
                        services.AudioRecordManager.registerMouseEvents(pushToTalkButtonRef.current?.state.HtmlElement)
                    }
                }

                setContainerVisibility(state === Stage.DIGITAL_HUMAN)
            })
        })().catch((error) => {
            console.error(error)
        });
    });

    useEffect(() => {
        // @todo check listining on change interfaceSize
        htmlRef.current && (htmlRef.current.style.fontSize = services.SettingManager.interfaceSizeStyle);
    })

    return <>
            <DigitalHumanContainer State={digitalHumanState} Hidden={!containerVisibility}>
                <VideoContainer LocalVideoRef={localVideoRef} AvatarVideoRef={avatarVideoRef} OnClick={ () => services.InterfaceManager.getCurrentDigitalHumanState() === DigitalHumanState.CHAT && chatPanelToggle() } />
                <MainContainer
                    Name={"DigitalHumanController"}
                    Visible={containerVisibility}
                      Top={[
                        <Logo />,
                        <Bar Name="topActionBar"
                            Left={[
                                <IconButton State={ButtonState.DEFAULT} Name={'ChatPanelControlTop'} Ref={chatPanelControlRef} IconVector={IconVector.CHAT} OnClick={() => chatPanelToggle()} />
                            ]}
                            Center={[
                                <LabelButton Label={'wantToTry'} Name={'editorial'} State={ButtonState.DEFAULT} OnClick={() => services.BackendManager.sendText('Try', sessionId)} />
                            ]}
                            Right={[
                                <DropdownController Services={services} Icon={IconVector.MENU} IconName='SettingControl' Alignment={DropdownAlignment.BOTTOM_LEFT} Template={DropdownTemplate.HOME} />,
                            ]}
                        />
                    ]}
                    Main={[
                        <ContentAreaController Services={services} />,
                        <CaptionController Services={services} />,
                    ]}
                    Bottom={[
                        <Bar
                            Name="bottomActionBar"
                            ref={bottomActionBarRef}
                            // Left={[
                            //     <IconButton State={ButtonState.BLOCKED} ref={historyPreviousControlRef}  Name="HistoryPreviousControl"  IconVector={IconVector.ARROW_LEFT} OnClick={() => historyPreviousControl()} />
                            // ]}
                            Center={[
                                <IconButton State={ButtonState.DEFAULT} Name={'PushToTalkControl'} ref={pushToTalkButtonRef} IconVector={IconVector.MICROPHONE} />,
                                <IconButton Name={'ChatPanelControlBottom'} Ref={chatPanelControlRef} IconVector={IconVector.CHAT} OnClick={() => chatPanelToggle()} />
                            ]}
                            // Right={[
                            //     <IconButton State={ButtonState.BLOCKED} ref={historyForwardControlRef} Name={'HistoryForwardControl'} IconVector={IconVector.ARROW_RIGHT} OnClick={() => historyForwardControl()} />
                            // ]}
                        />
                    ]}
                />
            </DigitalHumanContainer>
        </>
}