import React, { RefObject } from 'react'
import { IconButton, IconVector, Label, Patterns, TextInput, TextInputState } from '@Components'

export interface IncrementerProps {
    Name?: string
    Label?: string
    DefaultValue?: number
    Interval?: number
    From?: number
    To?: number
}

export class Incrementer extends React.Component<
    IncrementerProps,
    {
        Label: string
        Value: number
        Interval: number
        From: number
        To: number
        InputRef: RefObject<TextInput>
        Highlighted: boolean
        Ref: React.RefObject<HTMLDivElement>
    }
> {
    constructor(props: IncrementerProps) {
        super(props)
        this.state = {
            Label: props.Label ? props.Label : '',
            Value: props.DefaultValue ? props.DefaultValue : 0,
            Interval: props.Interval ? props.Interval : 1,
            From: props.From ? props.From : 0,
            To: props.To ? props.To : 200,
            Highlighted: false,
            Ref: React.createRef(),
            InputRef: React.createRef(),
        }
    }

    componentDidMount() {
        this.updateInput()
    }

    componentDidUpdate() {
        this.updateInput()
    }

    updateInput() {
        if (this.state.InputRef.current) this.state.InputRef.current.updateInput(this.state.Value.toString())
    }

    updateFromInput = (value: number) => {
        if (value >= this.state.From && value <= this.state.To) {
            this.setState({ Value: value })
            return true
        }
        return false
    }

    decrement = () => {
        const newValue = this.state.Value - this.state.Interval
        if (newValue >= this.state.From) this.setState({ Value: newValue })
    }

    increment = () => {
        const newValue = this.state.Value + this.state.Interval
        if (newValue <= this.state.To) this.setState({ Value: newValue })
    }

    render() {
        return (
            <div className={`incrementer ${this.props.Name || 'basic'}`} ref={this.state.Ref}>
                <div className={'formContainer'}>
                    <div className={`labelContainer`}>
                        <Label Text={this.props.Label} Name={'increment'} />
                    </div>
                    <div className={'incrementContainer'}>
                        <IconButton IconVector={IconVector.CIRCLE_MINUS} Name={'decrement'} OnClick={this.decrement} />
                        <div className={`input`}>
                            <TextInput
                                State={TextInputState.BLOCKED}
                                Name={`increment`}
                                Size={this.state.To?.toString().length}
                                ref={this.state.InputRef}
                                Pattern={Patterns.NUMBER}
                                Handler={this.updateFromInput}
                            />
                        </div>
                        <IconButton IconVector={IconVector.CIRCLE_PLUS} Name={'increment'} OnClick={this.increment} />
                    </div>
                </div>
            </div>
        )
    }
}
