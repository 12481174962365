import { UneeqEventObserver } from './UneeqEventObserver'
import { IServices } from '@Entities/Interfaces/IServices'
import { plainToInstance } from 'class-transformer'
import { Message } from '@Entities'


export class AvatarAnswerObserver extends UneeqEventObserver {

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(services: IServices) { super(services) }

    next(value: any): void {
        if(!value.answerAvatar)
            console.warn("No answerAvatar was found in the response");

        const message = plainToInstance(Message,JSON.parse(value.answerAvatar).instructions);

        this.services.HistoryManager.pushMessage(message);

        //When there is just instructions without speech,
        // it is necessary to start the message here
        if(!message.speech)
            this.services.HistoryManager.startMessage();
    }

    complete = (): void => console.log('Avatar Answer Observer - executed');
    error = (err: any): void => console.error(`Avatar Answer Observer`, err);
}