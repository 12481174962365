import React, { useRef, useState } from 'react'
import { IServices } from '@Entities/Interfaces/IServices'
import { Actor, DigitalHumanState, Stage, Translation } from '@Entities/Enums'
import {
    Bar,
    Bubble,
    BubbleState,
    ButtonState,
    CheckButton,
    DigitalHumanContainer,
    DropdownAlignment,
    Group,
    GroupAlignment,
    IconLabelButton,
    IconVector,
    MainContainer,
} from '@Components'
import { DropdownController, DropdownTemplate } from './ContentControllers/DropdownController'
import { t } from 'i18next'
import { I18n } from '@Application/Translation'

export function InitialController(services: IServices) {
    const [containerVisibility, setContainerVisibility] = useState(true)
    const [isStartDisabled, setIsStartDisabled] = useState(true)
    const [, setLang] = useState(I18n.language)
    const startRef = useRef<HTMLDivElement | null>(null)

    services.InterfaceManager.onStageChanged((state) => setContainerVisibility(state === Stage.INITIAL))
    
    I18n.on('languageChanged', (lang) => setLang(lang))

    const handleClick = () => {
        if (isStartDisabled) startRef.current?.scrollIntoView({ behavior: 'smooth' })
        setIsStartDisabled((isStartDisabled) => !isStartDisabled)
    }

    const start = async () => {
        services.BackendManager.start()
        services.InterfaceManager.Stage = Stage.LOADING
    }

    return containerVisibility ? (
        <DigitalHumanContainer State={DigitalHumanState.CHAT}>
            <MainContainer
                key="InitialController"
                Name={`InitialController`}
                Visible={containerVisibility}
                Top={[
                    <Bar
                        Name="topActionBar"
                        Right={[
                            // <DropdownController
                            //     Services={services}
                            //     key="LanguageSelector"
                            //     Icon={IconVector.UNION}
                            //     IconName="LanguageSelector"
                            //     Alignment={DropdownAlignment.BOTTOM_LEFT}
                            //     Template={DropdownTemplate.LANGUAGE_SELECTION}
                            // />,
                            <DropdownController
                                Services={services}
                                key="SettingControl"
                                Icon={IconVector.TEXT_SIZE}
                                IconName="SettingControl"
                                Alignment={DropdownAlignment.BOTTOM_LEFT}
                                Template={DropdownTemplate.INTERFACE_SIZE_NO_BAR}
                            />,
                        ]}
                    />,
                ]}
                Main={[
                    <div className="contentArea noFooter">
                        <div
                            className="contentScrollArea scrollTop"
                            id="contentScrollArea"
                        >
                            <div className="contentGroup">
                                <Group
                                    alignment={GroupAlignment.left}
                                    expands={false}
                                    hasMargins={false}
                                    isWrapped={true}
                                    HtmlRef={React.createRef<HTMLInputElement>()}
                                >
                                    {/* @ts-ignore */}
                                    {t(Translation.HOMESCREEN_BUBBLES, { returnObjects: true }).map((label, idx) => (
                                        <Bubble
                                            Actor={Actor.AVATAR}
                                            Label={label.text}
                                            key={`initial_bubbles_${idx}`}
                                            State={BubbleState.ACTIVE}
                                        />
                                    ))}
                                </Group>
                                <Group
                                    alignment={GroupAlignment.right}
                                    expands={false}
                                    hasMargins={true}
                                    isWrapped={false}
                                    HtmlRef={React.createRef<HTMLInputElement>()}
                                >
                                    <CheckButton
                                        State={ButtonState.DEFAULT}
                                        Name="editorial"
                                        Label={Translation.HOMESCREEN_AGREEMENT}
                                        Checked={false}
                                        OnClick={() => handleClick()}
                                    />
                                    <div
                                        ref={startRef}
                                        style={{ visibility: isStartDisabled ? 'hidden' : 'visible' }}
                                        className="editorial"
                                    >
                                        <IconLabelButton
                                            Label={Translation.HOMESCREEN_START_BUTTON}
                                            IconVector={IconVector.CHEVRON_RIGHT}
                                            State={ButtonState.DEFAULT}
                                            OnClick={() => start()}
                                            Name="editorial"
                                        />
                                    </div>
                                </Group>
                            </div>
                        </div>
                    </div>,
                ]}
                Bottom={[]}
            />
        </DigitalHumanContainer>
    ) : null
}
