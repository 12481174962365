import React, { useLayoutEffect} from 'react'
import { useEffect, useRef, useState } from 'react'
import { MediaType } from '@Entities/Enums'
import { Highlight } from '../Highlight/Highlight'
import VideoPlayer from '../VideoPlayer/VideoPlayer'

export enum MediaOrientation {
    VERTICAL='vertical',
    HORIZONTAL='horizontal',
    SQUARE='square'
}

export interface MediaPreviewProps {
    Source: string,
    MediaType: MediaType,
    Highlighted?: boolean
}

export function MediaPreview(props: MediaPreviewProps) {
    const mediaReference=useRef<any>()
    const [mediaOrientation,setMediaOrientation]=useState<MediaOrientation>();
    const [highlighted, setHighlighted]=useState(props.Highlighted || false)
    const [extreme, setExtreme] = useState(false)
    const [loading, setLoading] = useState(false)
    useLayoutEffect(() => {
        if(props.MediaType!==MediaType.VIDEO) {
            setLoading(true)
            const image=new Image()
            image.src=props.Source
            image.onload=() => {
                if(mediaReference.current) {
                    const ratio=image.naturalWidth/image.naturalHeight
                    let orientation=ratio>1? MediaOrientation.HORIZONTAL:ratio<1? MediaOrientation.VERTICAL:MediaOrientation.SQUARE
                    setMediaOrientation(orientation)
                    const height=mediaReference.current.offsetWidth/ratio
                    const currentFontSize=window.getComputedStyle(mediaReference.current).getPropertyValue("font-size")
                    if(height>2*parseInt(currentFontSize)) {setExtreme(true)} else {setExtreme(false)}
                    setLoading(false)
                }
            }
        } else {
            setLoading(false)
        }
    },[props.Source, props.MediaType])
    useEffect(() => {
        if(props.Highlighted!==undefined) {
            setHighlighted(props.Highlighted)
        }
    },[props.Highlighted])
    return (
        <div className={`mediaPreview ${props.MediaType} ${mediaOrientation} ${extreme ? 'extreme' : ''}` } ref={mediaReference}>
            {loading? <div> Loading... </div> :
            // switch from "Loading..." to the media is a bit jarring, probably should be fixed somehow in the future, maybe a loading component?
                props.MediaType===MediaType.ICON? 
                    <Highlight
                        Name='box'
                        Highlighted={highlighted}
                        Child={
                            <img src={props.Source} alt={`Media ${props.MediaType}`} onClick={()=>{console.log("Image onclick placeholder")}}  />
                        } />
                    :
                    props.MediaType===MediaType.VIDEO?
                        <VideoPlayer source={props.Source}/>
                    :
                        <img src={props.Source} alt={`Media ${props.MediaType}`} onClick={()=>{console.log("Image onclick placeholder")}}  />
            }   
            </div>
        )
    }
