import { Translation } from '@Entities/Enums'
import { Label, ButtonState } from '@Components'
import React from 'react'

export interface CheckButtonProps {
    Name?: string
    State: ButtonState
    Label: Translation | string
    OnClick?: () => void
    Checked: boolean
}

export class CheckButton extends React.Component<CheckButtonProps, {}> {
    state = {
        checked: this.props.Checked,
    }
    componentDidUpdate(prevProps: Readonly<CheckButtonProps>): void {
        if(prevProps.Checked!==this.props.Checked) {
            this.setState({
                ...this.state,
                checked: this.props.Checked,
            })
        }
    }
    render() {
        return (
            <div
                className={`checkButton ${this.props.State} basic ${this.props.Name ? this.props.Name : ''}`}
                onClick={() => {
                    this.props.OnClick && this.props.OnClick()
                    this.setState({
                        ...this.state,
                        checked: !this.state.checked,
                    })
                }}
            >
                <div className="container">
                    <Label Text={this.props.Label} />
                    <div className="content">
                        <input
                            type="checkbox"
                            checked={this.state.checked || false}
                            onChange={()=>{}}
                        />
                        <div className="checkbox">
                            <div className="checkmark" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
