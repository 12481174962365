import React from 'react'
import { Actor, MediaType } from '@Entities/Enums'
import { Label, Highlight } from '@Components'
import {MediaPreview} from './MediaPreview'

export enum BubbleState {
    ACTIVE = 'active',
    DISABLED = 'disabled',
}

export interface BubbleProps {
    Label: String
    State: BubbleState
    Actor: Actor
    Source?: string
    MediaType?: MediaType
    Highlighted?: boolean
}

export class Bubble extends React.Component<
    BubbleProps,
    {
        State: BubbleState
        Highlighted: boolean
        Ref: React.RefObject<HTMLDivElement>
    }
> {
    constructor(props: BubbleProps) {
        super(props)
        this.state={State: this.props.State,Highlighted: this.props.Highlighted || false, Ref: React.createRef() }
    }
    componentDidUpdate(prevProps: BubbleProps) {
        if(prevProps!==this.props) {
            this.setState({...this.state, ...this.props})
        }
    }
    render(): React.ReactNode {
        if (this.props.State === BubbleState.DISABLED) return null
        
        // MediaBubble
        if(this.props.Source&&this.props.MediaType) {
            // MediaBubble with Icon
            if(this.props.MediaType===MediaType.ICON) {
                return (
                    <div className={`media bubble avatar`}>
                        <MediaPreview Source={this.props.Source} MediaType={this.props.MediaType} Highlighted={this.state.Highlighted} />
                        {this.props.Label &&
                            <Highlight
                                Highlighted={this.state.Highlighted}
                                Name="box"
                                Child={
                                    <div ref={this.state.Ref} className={`bubble avatar`}>
                                        <Label Text={this.props.Label} />
                                    </div>
                                }
                            />}
                    </div>
                )
            }
            // MediaBubble with Image/Video
            else return (
                <Highlight
                    Highlighted={this.state.Highlighted}
                    Name="box"
                    Child={
                        <div ref={this.state.Ref} className={`media bubble avatar`}>
                            <MediaPreview Source={this.props.Source} MediaType={this.props.MediaType} />
                            {this.props.Label&&
                                <div className={`bubble avatar`}>
                                    <Label Text={this.props.Label} />
                                </div>
                            }
                        </div>
                    }
                />
            )
        }
        
        // Regular Bubble
        return (
            this.props.Label && (
                <Highlight
                    Highlighted={this.state.Highlighted}
                    Name="box"
                    Child={
                        <div ref={this.state.Ref} className={`bubble ${this.props.Actor}`}>
                            <Label Text={this.props.Label} />
                        </div>
                    }
                />
            )
        )
    }
}
