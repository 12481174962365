export enum UneeqEvent {
    /*
      The application has loaded and configuration validated.
      The session may now be started by calling uneeqStartSession()
    */
    READY = 'Ready',

    // Session state has been updated (Initial, WaitingToStart, AvatarUnavailable, MajorError, Live, Paused, Ended)
    SESSION_STATE_UPDATE = 'SessionStateUpdate',

    SESSION_LIVE = 'SessionLive',

    SESSION_ENDED = 'SessionEnded',

    // The layout mode has been updated: LayoutModeUpdate.layoutMode
    LAYOUT_MODE_UPDATE = 'LayoutModeUpdate',

    // The digital human session ID
    SESSION_ID = 'SessionId',

    // At least one digital human is available to be started
    AVATAR_AVAILABLE = 'AvatarAvailable',

    // No digital humans are available to be started
    AVATAR_UNAVAILABLE = 'AvatarUnavailable',

    // A question was asked to the Digital Human
    AVATAR_QUESTION_TEXT = 'AvatarQuestionText',

    // Digital human has a response
    AVATAR_ANSWER = 'AvatarAnswer',

    // Content has been provided by the NLP system to be rendered
    AVATAR_ANSWER_CONTENT = 'AvatarAnswerContent',

    /*
      JSON data that was provided by the NLP system in the 'instructions' field.
      This data can be used to pass custom instructions from the NLP system into your front end application.
      For example, you could set some customData of a product that you want to display on the website.
    */
    CUSTOM_DATA = 'CustomData',

    // Digital human has begun speaking
    STARTED_SPEAKING = 'StartedSpeaking',

    // Digital human has finished speaking
    FINISHED_SPEAKING = 'FinishedSpeaking',


    SPEECH_TRANSCRIPTION = 'SpeechTranscription',

    // Permission to use recording devices has been granted by the user.
    DEVICE_ENABLED = 'DeviceEnabled',

    DEVICE_PERMISSION_ALLOWED = 'DevicePermissionAllowed',

    // A voice recording has been initiated
    RECORDING_STARTED = 'RecordingStarted',

    // A voice recording has been ended
    RECORDING_STOPPED = 'RecordingStopped',

    /*
      Digital human audio could not be played due to browsers auto play policy. Digital human was started with
      audio muted. You may call uneeqUnmuteDigitalHuman() following user click/key event to unmute the digital human.
    */
    DIGITAL_HUMAN_PLAYED_IN_MUTED_MODE_SUCCESS = 'DigitalHumanPlayedInMutedModeSuccess',

    // The digital human was unmuted, or started in unmuted mode.
    DIGITAL_HUMAN_UNMUTED = 'DigitalHumanUnmuted',

    // An error occurred with the digital human video. Refer to contents of message for details.
    DIGITAL_HUMAN_VIDEO_ERROR = 'DigitalHumanVideoError',

    /*
      The digital human video could not be played for some reason. Most likely blocked due to the browsers auto play
      policy (the user has not interacted with the page before attempting to play audio).
    */
    DIGITAL_HUMAN_FAILED_TO_PLAY = 'DigitalHumanFailedToPlay',

    // [Deprecated]
    AVATAR_TEXT_INPUT_FINISHED = 'AvatarTextInputFinished',

    // Provides streaming status updates for both client and server side.
    CLIENT_MEDIA_STREAM_UPDATE = 'ClientMediaStreamUpdate'

}

