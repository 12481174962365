import 'reflect-metadata'
import { useState } from 'react'
import { IServices } from '@Entities/Interfaces/IServices'
import { InitialController } from '@Application/Controllers/InitialController'
import { LoadingController } from '@Application/Controllers/LoadingController'
import { DigitalHumanController } from '@Application/Controllers/DigitalHumanController'
import { UneeqEvent } from '@Entities/Enums'
import { I18n } from '@Translation'
import { Background, Modal } from '@Components'
import { Helmet } from 'react-helmet'
import {
    AvatarAnswerObserver,
    AvatarFinishSpeakingObserver,
    AvatarSessionEndedObserver,
    AvatarStartedSpeakingObserver,
    ClientMediaStreamUpdateObserver,
    AvatarReadyObserver,
    AvatarSessionLive,
    SpeechTranscriptionObserver,
} from '@Observers'
import {
    AudioRecordManager,
    BackendManager,
    ConfigManager,
    HistoryManager,
    InterfaceManager,
    SettingManager,
    UneeqManager,
} from '@Services'
import '@Themes'

export function Application() {
    const configManager = ConfigManager.getInstance()
    const interfaceManager = InterfaceManager.getInstance()
    const historyManager = HistoryManager.getInstance()
    const audioRecordManager = AudioRecordManager.getInstance()
    const uneeqManager = UneeqManager.getInstance()
    const backendManager = BackendManager.getInstance()
    const settingsManager = SettingManager.getInstance()

    const [services] = useState<IServices>({
        InterfaceManager: interfaceManager,
        BackendManager: backendManager,
        HistoryManager: historyManager,
        AudioRecordManager: audioRecordManager,
        UneeqManager: uneeqManager,
        ConfigManager: configManager,
        SettingManager: settingsManager,
    })

    const bugherdSource = `https://www.bugherd.com/sidebarv2.js?apikey=${configManager.config.bugherd.key}`
    const bugherdEnabled = configManager.config.bugherd.enabled
    const translations = configManager.config.i18n
    const defaultLanguage = configManager.config.backend.DefaultLanguage
    const languages = Object.keys(translations)
    const urlSearchParams = new URLSearchParams(window.location.search)

    I18n.init({
        defaultNS: 'common',
        resources: translations as {},
    })

    I18n.changeLanguage(languages.find(l => l === urlSearchParams.get('lang')) || defaultLanguage)

    // Monitor Messages from the Uneeq
    services.UneeqManager.subscribeEventObserver(UneeqEvent.READY, new AvatarReadyObserver(services))
    services.UneeqManager.subscribeEventObserver(UneeqEvent.CLIENT_MEDIA_STREAM_UPDATE, new ClientMediaStreamUpdateObserver(services))
    services.UneeqManager.subscribeEventObserver(UneeqEvent.SESSION_LIVE, new AvatarSessionLive(services))
    services.UneeqManager.subscribeEventObserver(UneeqEvent.AVATAR_ANSWER, new AvatarAnswerObserver(services))
    services.UneeqManager.subscribeEventObserver(UneeqEvent.STARTED_SPEAKING, new AvatarStartedSpeakingObserver(services))
    services.UneeqManager.subscribeEventObserver(UneeqEvent.FINISHED_SPEAKING, new AvatarFinishSpeakingObserver(services))
    services.UneeqManager.subscribeEventObserver(UneeqEvent.SPEECH_TRANSCRIPTION, new SpeechTranscriptionObserver(services))
    services.UneeqManager.subscribeEventObserver(UneeqEvent.SESSION_ENDED, new AvatarSessionEndedObserver(services))

    return (
        <>
            {bugherdEnabled && (
                <Helmet>
                    <script type="text/javascript" src={bugherdSource} async></script>
                </Helmet>
            )}
            <Modal />
            <Background />
            <InitialController {...services} />
            <LoadingController {...services} />
            <DigitalHumanController {...services} />
        </>
    )
}
