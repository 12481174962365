import { ButtonState, Icon, IconVector } from '@Components'
import React, { MouseEventHandler, PropsWithRef, RefObject } from 'react'

export interface IconButtonProps {
    Name?: string
    Ref?: React.RefObject<HTMLButtonElement>
    State?: ButtonState
    IconVector: IconVector
    OnClick?: MouseEventHandler<HTMLButtonElement>
}

export class IconButton extends React.Component<
    PropsWithRef<IconButtonProps>,
    {
        PreviousState: ButtonState
        State: ButtonState
        HtmlElement: RefObject<HTMLButtonElement>
    }
> {
    constructor(props: IconButtonProps) {
        super(props)
        this.state = {
            State: this.props.State || ButtonState.DEFAULT,
            PreviousState: this.props.State || ButtonState.DEFAULT,
            HtmlElement: React.createRef(),
        }
    }

    onHover: MouseEventHandler<HTMLButtonElement> = () => {
        if(this.state.State === ButtonState.BLOCKED) return
        this.setState({
            PreviousState: this.state.State,
            State: ButtonState.HOVER
        })
    }
    onLeave: MouseEventHandler<HTMLButtonElement> = () => {
        if(this.state.State === ButtonState.BLOCKED) return
        this.setState({
            PreviousState: ButtonState.HOVER,
            State: this.state.PreviousState
        })
    }

    render() {
        return (
            <button
                ref={this.state.HtmlElement}
                onMouseEnter={this.onHover}
                onMouseLeave={this.onLeave}
                className={`iconButton ${this.state.State} basic ${this.props.Name ? this.props.Name : ''}`}
                onClick={
                    this.state.State === ButtonState.BLOCKED
                        ? () => console.info('Button is blocked')
                        : this.props.OnClick
                }
            >
                <div className="container marginTop">
                    <div className="asset">
                        <Icon vector={this.props.IconVector} />
                    </div>
                </div>
            </button>
        )
    }
}
