import { UneeqEventObserver } from './UneeqEventObserver'
import { IServices } from '@Entities/Interfaces/IServices'

export class SpeechTranscriptionObserver extends UneeqEventObserver{

    constructor(services: IServices) { super(services) }

    next(value:any): void {
        console.info("[TranscriptionObserver] - executed", value);
    }

    complete = (): void => console.log('[TranscriptionObserver] - executed');
    error = (err: any): void => console.error(`[TranscriptionObserver]`, err);
}