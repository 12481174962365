import React from 'react'
import { InputSlider } from '@Components'
import { SettingManager } from '@Services'

export interface InterfaceSizeProps {
    SettingManager: SettingManager
}

export interface InterfaceSizeStates {}

export class InterfaceSize extends React.Component<InterfaceSizeProps, InterfaceSizeStates> {
    render(): React.ReactNode {
        const setInterfaceSize = (value: number) => {
            this.props.SettingManager.interfaceSize = value

            document.body.parentElement && (document.body.parentElement.style.fontSize = this.props.SettingManager.interfaceSizeStyle)
        }

        return (
            <InputSlider
                Items={[
                    {
                        Label: '50%',
                        Value: -2,
                    },
                    {
                        Label: '75%',
                        Value: -1,
                    },
                    {
                        Label: '100%',
                        Value: 0,
                        IsActive: true,
                    },
                    {
                        Label: '125%',
                        Value: 1,
                    },
                    {
                        Label: '150%',
                        Value: 2,
                    },
                ]}
                OnChangeHandler={(activeItem, e) => {
                    setInterfaceSize(activeItem.Value)
                }}
                OnClickPrevHandler={(activeItem, e) => {
                    setInterfaceSize(activeItem.Value)
                }}
                OnClickNextHandler={(activeItem, e) => {
                    setInterfaceSize(activeItem.Value)
                }}
            />
        )
    }
}
