import Crypto from 'crypto-browserify'
import { ButtonElement, IncrementerElement, TextElement } from '@Entities'
import { Expose } from 'class-transformer'

export class Message {
        @Expose()
        id?: string;

        @Expose()
        page: string = "";

        @Expose()
        intent: string = "";

        @Expose()
        speech: string = ''

        @Expose()
        element?: TextElement | ButtonElement | IncrementerElement;

        getGroupId = () => Crypto.createHash('md5').update(this.page + this.intent).digest("hex");
}