import {
    Bubble,
    BubbleProps,
    BubbleState,
    ButtonState,
    IconLabelButton,
    IconLabelButtonProps,
    Incrementer,
    IncrementerProps,
    Label,
    LabelButton,
    LabelButtonProps,
    LabelProps,
} from '../../../../Visual/Components'
import { ButtonElement, IncrementerElement, TextElement } from '@Entities'
import { Actor, ElementType } from '@Entities/Enums'

export class ContentElementFactory {
    static createBubble(element: TextElement, actor: Actor) {
        return {
            element: element,
            component: Bubble,
            type: ElementType.TEXT,
            props: {
                Name: element.id,
                key: element.id,
                Actor: actor,
                State:BubbleState.ACTIVE,
                Label: element.label
            } as BubbleProps
        }
    }

    static createLabel(type: string, element: TextElement)  {
        return {
            element: element,
            component: Label,
            type: ElementType.TEXT,
            props: {
                Name: type,
                key: `${element.id}_${type}`,
                Text: element.label
            } as LabelProps
        }
    }

    static createIncrementer(element: IncrementerElement)  {
        return {
            element: element,
            component: Incrementer,
            type: ElementType.INCREMENTER,
            props: {
                key: element.id,
                Name: "editorial",
                Label: element.label,
                DefaultValue: Number(element.defaultValue),
                From: Number(element.from),
                To: Number(element.to),
                Interval: Number(element.interval)
            } as IncrementerProps
        };
    }

    static createButton(element: ButtonElement, action: () => void)  {
        let basicProps = {
            key: element.id,
            Name: "editorial",
            State: ButtonState.DEFAULT,
            LinkProps: element.href ? { Href: element.href, Target: element.target, Name: "editorial" } : undefined,
            Label: element.label,
            OnClick: () => action(),
        } as LabelButtonProps | IconLabelButtonProps

        if(element.icon){
                return { element: element, component: IconLabelButton, type: ElementType.BUTTON, props: {...basicProps, IconVector: element.icon} as IconLabelButtonProps}
        }else{
                return { element: element, component: LabelButton, type: ElementType.BUTTON, props: { ...basicProps }  as LabelButtonProps }
        }
    }

    static createTitle(element: TextElement, contentContainer: string)  {
        return ContentElementFactory.createLabel(`${contentContainer}Title`, element)
    }

    static createSubTitle(element: TextElement, contentContainer: string)  {
        return ContentElementFactory.createLabel(`${contentContainer}Subtitle`, element)
    }

}