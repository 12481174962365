import React from 'react'
import { CaptionState } from '../../Visual/Components'

export class SettingManager extends React.Component<{}, {}> {
    private static instance: SettingManager

    private _captionStyle: CaptionState

    private _interfaceSize: number

    private _interfaceSizeRatio: number

    private constructor(props?: any) {
        super(props)

        this._captionStyle = CaptionState.DISABLED
        this._interfaceSize = 0
        this._interfaceSizeRatio = 8
    }

    static getInstance(props?: any): SettingManager {
        if (!SettingManager.instance) SettingManager.instance = new SettingManager(props)

        return SettingManager.instance
    }

    get captionStyle(): CaptionState {
        return this._captionStyle
    }

    set captionStyle(captionStyle: CaptionState) {
        this._captionStyle = captionStyle
    }

    get interfaceSize(): number {
        return this._interfaceSize
    }

    get interfaceSizeStyle(): string {
        return `${1 + (this._interfaceSize / this._interfaceSizeRatio)}em`
    }

    set interfaceSize(interfaceSize: number) {


        this._interfaceSize = interfaceSize
    }

}
