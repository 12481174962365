import { Subject } from 'rxjs'
import { Message } from '@Entities'
import { Key } from 'react'

export class HistoryManager {
    private static instance: HistoryManager;

    public static getInstance(): HistoryManager {
        if(!HistoryManager.instance)
            HistoryManager.instance = new HistoryManager();
        return HistoryManager.instance
    }

    private messages: Message[] = [];
    private messageStartObservable = new Subject<Message>();
    private messageStopObservable = new Subject<Message>();

    onMessageStart(callback: (message: Message) => void) {
        this.messageStartObservable.subscribe(callback);
    }

    onMessageStop(callback: (message: Message) => void): void {
        this.messageStopObservable.subscribe(callback);
    }

    public pushMessage(message: Message) {
        this.messages.push(message);
    }

    public stopMessage() {
        if(!this.messages) return;
        this.messageStopObservable.next(this.messages[this.messages.length - 1]);
    }

    public startMessage() {
        if(!this.messages) return;
        this.messageStartObservable.next(this.messages[this.messages.length - 1]);
    }

    public getLastGroupIdByLastMessage() {
        return this.getLastMessage().getGroupId();
    }

    public getLastMessage() {
        return this.messages[this.messages.length - 1] || null;
    }

    public allMessagesByGroupId(groupId: string) {
        return this.messages.filter(message => message.getGroupId() === groupId);
    }

    //If has button or incrementer has extracontent
    public hasExtraContentByGroupId(groupId: string) {
        const messages = this.allMessagesByGroupId(groupId);
        return messages.some(message => message.element && (
            message.element.type === 'button' || message.element.type === 'incrementer'
            )
        )
    }

    public removeMessageById(id: string){
        this.messages = this.messages.filter(message => message.id !== id);
    }

    public hasExtraContentByLastGroupId() {
        const groupId = this.getLastGroupIdByLastMessage();
        return this.hasExtraContentByGroupId(groupId);
    }

    //Remove all messages by groupIds
    public removeMessagesByGroupId(groupId: Key[]) {
        this.messages = this.messages.filter(message => !groupId.includes(message.getGroupId()));
    }
}
